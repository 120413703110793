/* eslint-disable max-classes-per-file */
import { ReactNode } from 'react';
import { ISelectOption, ISelectOptionWithContent } from '../model/helper';
import { DaySchedule, type IAddress, IServiceOption } from '../model/UserInfo';
import {
  ApiResponseStatus,
  gcsUploadFileToSignedUrl,
  getRequest,
  postRequest,
} from '../util/ApiRequest';
import { FieldValidator } from '../util/fields/FieldValidators';
import { ServiceException } from './Exception';
import { NameValueDict } from './FormTypes';
import mime from 'mime-types';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ModificationService } from '../util/ServiceConstants';
import { publishEvent } from '~/util/events';

export enum TextFieldType {
  Text = 'text',
  Email = 'email',
  Textarea = 'textarea',
  Number = 'number',
  Password = 'password',
  Date = 'date',
  Hidden = 'hidden',
}

export abstract class IFile {
  /**
   * Key for storing the object in the object store
   */
  abstract objectKey: string;

  /**
   * File description, if it exists
   */
  abstract description?: string;

  /**
   * either a remote url or object url
   */
  abstract url: string;

  get isImage(): boolean {
    const mimeType = this.getMimeType();
    if (!mimeType) return false;
    return mimeType.startsWith('image/');
  }

  get isPdf(): boolean {
    const mimeType = this.getMimeType();
    if (!mimeType) return false;
    return mimeType.indexOf('pdf') > -1;
  }

  get isVideo(): boolean {
    const mimeType = this.getMimeType();
    if (!mimeType) return false;
    return mimeType.startsWith('video');
  }

  abstract getMimeType(): string | null;
}

export class UploadedFile extends IFile {
  private _mimetype: null | string | false = false;

  mimetype: any;

  constructor(
    public objectKey: string,
    public url: string,
    public description?: string
  ) {
    super();
  }

  getMimeType(): string | null {
    if (this._mimetype !== false) return this._mimetype;
    if (!this.url) {
      this._mimetype = null;
      return this._mimetype;
    }

    const strippedUrl = this.url.split('?')[0];
    this._mimetype = mime.lookup(strippedUrl);
    if (this._mimetype === false) this._mimetype = null; // don't test again
    return this._mimetype;
  }
}

export class PendingUploadFile extends IFile {
  public objectUrl: string | null = null;

  constructor(
    public objectKey: string,
    public fileData: File,
    /**
     * The signed url used to upload object to object storage
     */
    public presignedUrl: string,
    /**
     * Headers to pass along in the request to GCS
     */
    public headers: Headers,
    /**
     * Whether or not the file was already uploaded successfully
     */
    public isUploaded: boolean,
    /**
     * Only set if there were errors with the upload
     */
    public error?: string,
    public description?: string
  ) {
    super();
  }

  getMimeType(): string | null {
    if (!this.fileData) return null;
    return this.fileData.type;
  }

  get url(): string {
    if (this.objectUrl) return this.objectUrl;
    this.objectUrl = URL.createObjectURL(this.fileData);
    return this.objectUrl;
  }

  close() {
    if (!this.objectUrl) return;
    try {
      URL.revokeObjectURL(this.objectUrl);
    } catch (e) {
      console.error(e);
    }
    this.objectUrl = null;
  }
}

export const getOptionLabelOrContent = (option: ISelectOptionWithContent) => {
  if (option.content) return option.content;
  return option.label;
};

export type FieldValueType =
  | undefined
  | null
  | string
  | string[]
  | ISelectOption
  | ISelectOption[]
  | DaySchedule[]
  | number
  | boolean
  | IFile[]
  | object[]
  | IAddress;

interface IDefaultField<T> {
  id: string;
  name: string;
  label?: ReactNode;
  centerLabel?: boolean;
  labelWidth?: number;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  isInline?: boolean;
  pattern?: string;
  placeholder?: string;
  smallText?: string;
  validators?: FieldValidator<T>[];
  value?: T;
  error?: string;
}

export abstract class DefaultField<T> implements IDefaultField<any> {
  public id: string;

  public name: string;

  public label?: ReactNode;

  public centerLabel?: boolean;

  public labelWidth?: number;

  public inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;

  public isInline?: boolean;

  public pattern?: string;

  public placeholder?: string;

  public smallText?: string;

  public validators?: FieldValidator<T>[];

  public value?: T;

  public readonly defaultValue?: T;

  public error?: string;

  constructor(initial: IDefaultField<T>) {
    this.error = initial.error;
    this.value = initial.value;
    this.defaultValue = initial.value;
    this.validators = initial.validators;
    this.smallText = initial.smallText;
    this.inputMode = initial.inputMode;
    this.isInline = initial.isInline;
    this.pattern = initial.pattern;
    this.placeholder = initial.placeholder;
    this.labelWidth = initial.labelWidth;
    this.centerLabel = initial.centerLabel;
    this.label = initial.label;
    this.name = initial.name;
    this.id = initial.id;

    makeObservable(this, {
      id: observable,
      name: observable,
      label: observable,
      centerLabel: observable,
      labelWidth: observable,
      inputMode: observable,
      isInline: observable,
      pattern: observable,
      placeholder: observable,
      smallText: observable,
      validators: observable,
      value: observable,
      defaultValue: false,
      error: observable,

      setValue: action.bound,
      setError: action.bound,
      validate: action.bound,
      reset: action.bound,
      isDirty: action.bound,
    });
  }

  public getValueOnSubmit(): Promise<any> {
    return Promise.resolve(this.value);
  }

  public setValue(value: T, allFields?: NameValueDict<Field>) {
    if (this.value !== value) publishEvent('field-value-changed');
    this.value = value;
    this.validate(allFields);
  }

  public setError(error: string) {
    this.error = error;
  }

  public validate(allFields?: NameValueDict<Field>) {
    for (const validator of this.validators || []) {
      const error = validator.validate(this.value, allFields);
      if (error) {
        this.error = error;
        return;
      }
    }
    this.error = ''; // There haven't been any validation errors, thus the error is set to the empty string
  }

  public isInvalid() {
    return !!(this.error && this.error.length > 0);
  }

  public reset() {
    this.value = this.defaultValue;
  }

  public isDirty() {
    return this.value !== this.defaultValue;
  }
}

export class TextLikeField extends DefaultField<string | number> {
  constructor(
    defaultFields: IDefaultField<string | number>,
    public type: TextFieldType
  ) {
    super(defaultFields);
  }
}

export class FileField extends DefaultField<File> {
  public objectUrl: string | null = null;

  constructor(
    defaultFields: IDefaultField<File>,
    public buttonText?: string
  ) {
    super(defaultFields);
  }

  get url(): string {
    if (this.objectUrl) return this.objectUrl;
    if (this.value) this.objectUrl = URL.createObjectURL(this.value);
    return this.objectUrl || '';
  }

  cleanup() {
    if (!this.objectUrl) return;
    try {
      URL.revokeObjectURL(this.objectUrl);
    } catch (e) {
      console.error(e);
    }
    this.objectUrl = null;
  }
}

export class ImageField extends DefaultField<IFile[]> {
  constructor(
    defaultFields: IDefaultField<IFile[]>,
    public multiple?: boolean,
    public allowsVideo?: boolean,
    public buttonText?: string
  ) {
    super(defaultFields);
  }

  // Throws ServiceException in case of unsuccessful submit
  public async getValueOnSubmit() {
    if (!this.value) return undefined;
    return Promise.all(
      this.value.map(async (file) => {
        if (file instanceof PendingUploadFile) {
          if (!file.isUploaded) {
            const response = await gcsUploadFileToSignedUrl(
              file.presignedUrl,
              file.headers,
              file.fileData
            );
            if (response.status === ApiResponseStatus.Error) {
              const fileName = file.fileData.name;
              file.error = `"${fileName}" could not be uploaded.`;
              throw new ServiceException(response.data);
            } else {
              file.isUploaded = true;
            }
          }
        }
        return { objectKey: file.objectKey, description: file.description };
      })
    );
  }

  public async onAddFiles(files: File[], appendToPreviousFiles: boolean) {
    try {
      const newFiles: IFile[] = [];
      let error = '';
      // pre-request
      await Promise.all(
        Array.from(files).map(async (file) => {
          const result = await postRequest('/file-upload-url', {
            fileName: file.name,
            contentType: file.type,
          });
          if (result.status === ApiResponseStatus.Success) {
            newFiles.push(
              new PendingUploadFile(
                result.data.objectKey, // objectKey
                file, // fileData,
                result.data.presignedUrl, // presignedUrl
                result.data.headers,
                false
              )
            );
          } else {
            error =
              'Could not get file upload url for some files. Please check uploaded files and try to re-upload';
          }
        })
      );
      runInAction(() => {
        const oldFiles = (appendToPreviousFiles && this.value) || [];
        this.value = [...oldFiles, ...newFiles];
        if (error) {
          this.error = error;
        }
      });

      return !error;
    } catch (error) {
      runInAction(() => {
        this.error = (error as Error).message;
      });
      return false;
    }
  }
}

export class SelectField extends DefaultField<string> {
  constructor(
    defaultFields: IDefaultField<string>,
    public options: ISelectOption[]
  ) {
    super(defaultFields);
  }
}

export class CheckboxField extends DefaultField<boolean> {
  constructor(
    defaultFields: IDefaultField<boolean>,
    public fieldLabel: string
  ) {
    super(defaultFields);
  }
}

export class RadioSelectField extends DefaultField<ISelectOption> {
  constructor(
    defaultFields: IDefaultField<ISelectOption>,
    public options: ISelectOptionWithContent[]
  ) {
    super(defaultFields);
  }
}

export class ValueOnlyRadioSelectField extends RadioSelectField {
  public async getValueOnSubmit() {
    return Promise.resolve(this.value?.value);
  }
}

export class MultiSelectField extends DefaultField<ISelectOption[]> {
  constructor(
    defaultFields: IDefaultField<ISelectOption[]>,
    public defaultOptions: ISelectOptionWithContent[],
    public hasCustomOptions: boolean,
    public customOptions?: ISelectOptionWithContent[],
    public customOptionPrompt?: string
  ) {
    super(defaultFields);
  }
}

export class ServicesField extends DefaultField<IServiceOption[]> {
  constructor(
    defaultFields: IDefaultField<IServiceOption[]>,
    public defaultOptions: ModificationService[],
    public hasCustomOptions: boolean,
    public customOptions?: ISelectOption[],
    public customOptionPrompt?: string
  ) {
    super(defaultFields);
  }
}

export class EnumField extends DefaultField<ISelectOption[]> {}

export class EnumStringField extends DefaultField<string[]> {}

export class ListField extends DefaultField<string[]> {}

export class GroupField extends DefaultField<DefaultField<any>[]> {
  public async getValueOnSubmit() {
    const fieldValues = await Promise.all(
      (this.value ?? []).map(async (field) => {
        const value: any = await field.getValueOnSubmit();
        return { [field.name]: value };
      })
    );

    return fieldValues.reduce((acc, fieldValue) => ({ ...acc, ...fieldValue }), { id: this.id });
  }

  public assignComputedValues(value: any) {
    this.value?.forEach((field) => {
      const newValue = value[field.name];
      if (newValue && 'isComputed' in field) {
        field.setValue(newValue);
      }
    });
  }

  public validate(allFields?: NameValueDict<Field>) {
    super.validate();

    // Now run validations for the subfields
    let foundError = false;
    for (const field of this.value || []) {
      field.validate(allFields);
      if (field.error) foundError = true;
    }

    if (foundError) {
      this.error = 'Please correct the errors in the highlighted fields';
    }
  }
}

export class RepeatedField extends DefaultField<GroupField[]> {
  constructor(defaultFields: IDefaultField<GroupField[]>) {
    super(defaultFields);
    if (!this.value) this.value = [];
  }

  public addField(field: GroupField) {
    this.value?.push(field);
  }

  public removeField(index: number) {
    this.value?.splice(index, 1);
  }

  public async getValueOnSubmit(): Promise<any[]> {
    return Promise.all(this.value?.map(async (field) => field.getValueOnSubmit()) || []);
  }

  public validate(allFields?: NameValueDict<Field>) {
    super.validate();

    // Now run validations for the subfields
    let foundError = false;
    for (const field of this.value || []) {
      field.validate(allFields);
      if (field.error) foundError = true;
    }

    if (foundError) {
      this.error = 'Please correct the errors in the highlighted fields';
    }
  }

  public assignComputedValues(value: any[]) {
    const idToValueMap = value.reduce((acc, val) => ({ ...acc, [val.id]: val }), {});
    this.value?.forEach((field) => {
      const newValue = idToValueMap[field.id];
      if (newValue) {
        field.assignComputedValues(newValue);
      }
    });
  }

  public setError(error: string, index?: number) {
    // If the error is meant for one of the subfields, set it on that field
    if (index !== undefined && this.value && this.value.length > index)
      this.value[index].setError(error);
    else this.error = error;
  }
}

export class ComputedField<T> extends DefaultField<T> {
  constructor(
    defaultFields: IDefaultField<T>,
    public isComputed: boolean = true
  ) {
    super(defaultFields);
  }

  public setValue(value: T) {
    this.value = value;
  }
}

export class HoursField extends DefaultField<DaySchedule[]> {}
export class URLField extends DefaultField<string> {
  validators = [];

  constructor(
    defaultFields: IDefaultField<string>,
    public type: URLField
  ) {
    super(defaultFields);
  }
}

export type Field =
  | TextLikeField
  | SelectField
  | CheckboxField
  | RadioSelectField
  | MultiSelectField
  | EnumField
  | ImageField
  | FileField
  | ListField
  | ServicesField
  | HoursField
  | URLField
  | GroupField
  | RepeatedField
  | ComputedField<any>;
