import React, { ReactNode, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { type Field, SelectField } from '~/types/Field';
import { Select } from './Select';

type StandardSelectFormFieldProps = {
  className?: string;
  // override label or null to disable the automatic field label
  label?: ReactNode | ReactNode[];
  selectClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeForm?: (field: Field, newValue: any) => boolean;
  field: SelectField;
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
};

export const StandardSelectFormField = observer(
  ({
    className,
    label,
    selectClassName,
    onChange,
    onChangeForm,
    field,
    disabled,
    invalid = false,
    required,
  }: StandardSelectFormFieldProps) => {
    const isInvalid = field.isInvalid() || invalid;

    if (!onChange && onChangeForm) {
      onChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
          onChangeForm(field, event.target.value);
        },
        [field, onChangeForm]
      );
    }

    const body = (
      <Select
        id={field.id}
        className={selectClassName}
        name={field.name}
        onChange={onChange}
        options={field.options}
        value={field.value}
        disabled={disabled}
        required={required}
        invalid={isInvalid}
        error={field.error}
      />
    );

    if ((!field.label && !label) || label === null) return body;

    return (
      <div className="field">
        <label className={className} htmlFor={field.id}>
          {label && <span className="input-label-text">{label}</span>}
          {!label && <span className="input-label-text">{field.label}</span>}
          {field.smallText && <small>{field.smallText}</small>}
          {body}
        </label>
      </div>
    );
  }
);

StandardSelectFormField.displayName = 'StandardSelectFormField';
